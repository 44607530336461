import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { getArticleOrCategorySlugs } from '../../utilities';
import { Image } from './../Common/Image';
import ArticleCardBlue from '../Common/Article/ArticleCardBlue';
import { Swiper, SwiperSlide } from 'swiper/react';

class BrowserContentByCategory extends Component {
     constructor(props) {
          super(props);
          this.state = {
               activeCategory: this.props.topCategories[1].node.categoryName
          };
     }

     browseCategory = (e, categoryName) => {
          e.preventDefault();
          this.setState({ activeCategory: categoryName });
     };

     render() {
          const { titleBrowserContent } = this.props;
          var featuredArticles = [];
          var topCategories = this.props.topCategories;

          topCategories.forEach(({ node }) => {
               if (node.categoryName == this.state.activeCategory) {
                    featuredArticles = node.categoryFeaturedArticle;
               }
          });

          const categoriesActiveIndex = topCategories.findIndex(({ node: category }) => this.state.activeCategory == category.categoryName);

          const handleSlideChange = (e) => {
               if (+e.currentBreakpoint < 992) {
                    const categoriesActive = topCategories[e.realIndex];
                    this.setState({ activeCategory: categoriesActive.node.categoryName });
               }
          };

          return (
               <section className={`home-browse`}>
                    <h2>{titleBrowserContent}</h2>
                    <div className="home-browse-wrapper">
                         <div className="container">
                              <div className="home-browse-title" role="tablist">
                                   <Swiper
                                        initialSlide={categoriesActiveIndex}
                                        slidesPerView="auto"
                                        centeredSlides={false}
                                        loop={true}
                                        onSlideChange={handleSlideChange}
                                        breakpoints={{
                                             320: {
                                                  centeredSlides: true,
                                                  centeredSlidesBounds: true,
                                                  grabCursor: true,
                                                  slideToClickedSlide: true
                                             },
                                             992: {
                                                  centeredSlides: false,
                                                  initialSlide: 0,
                                                  slideToClickedSlide: false
                                             }
                                        }}
                                   >
                                        {topCategories &&
                                             topCategories.map(({ node }, i) => {
                                                       return (
                                                            <SwiperSlide key={i}>
                                                                 <div
                                                                      className={`home-browse-title-box ${this.state.activeCategory == node.categoryName ? 'active' : ''}`}
                                                                      key={i}
                                                                      role="tab"
                                                                      aria-selected={this.state.activeCategory == node.categoryName ? 'true' : 'false'}
                                                                 >
                                                                      <span onClick={(e) => this.browseCategory(e, node.categoryName)} className="home-browse-title-box-image">
                                                                           <Image image={node.categoryIcon} />
                                                                      </span>
                                                                      <span onClick={(e) => this.browseCategory(e, node.categoryName)} className="home-browse-title-box-text">
                                                                           {node.categoryName}
                                                                      </span>
                                                                      <span className="home-browse-title-box-triangles"></span>
                                                                 </div>
                                                            </SwiperSlide>
                                                       );
                                                  }
                                             )}
                                   </Swiper>
                              </div>
                         </div>
                         <div className="container">
                              <div className="home-browse-article">
                                   <span className="home-browse-title-box-triangles"></span>
                                   <div className="row articles-wrap">
                                        {featuredArticles &&
                                             featuredArticles.map((featuredArticle, i) => {
                                                  let slugs =
                                                       '/healthzone/' +
                                                       getArticleOrCategorySlugs(featuredArticle.category)
                                                            .reverse()
                                                            .join('/') +
                                                       '/' +
                                                       featuredArticle.slug +
                                                       '/';

                                                  return (
                                                       <div className="col-md-6 col-lg-3 article-block" key={i}>
                                                            <ArticleCardBlue article={featuredArticle} slugs={slugs} />
                                                       </div>
                                                  );
                                             })}
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          );
     }
}

export default () => {
     return (
          <StaticQuery
               query={graphql`
                    query BrowserContentByCategoryQuery {
                         allContentfulHzCategory( limit: 5 sort: {fields: createdAt} filter: {isTopCategory: {eq: true}}) {
                              edges {
                                   node {
                                        slug
                                        categoryName
                                        categoryIcon {
                                             file {
                                                  url
                                                  details {
                                                       image {
                                                            width
                                                            height
                                                       }
                                                  }
                                             }
                                        }
                                        shortDescription
                                        featuredImage {
                                             file {
                                                  url
                                             }
                                        }
                                        categoryIntroImage {
                                             file {
                                                  url
                                             }
                                        }
                                        categoryFeaturedArticle {
                                             contentName
                                             slug
                                             shortDescription
                                             featuredImage {
                                                  file {
                                                       url
                                                       details {
                                                            image {
                                                                 width
                                                                 height
                                                            }
                                                       }
                                                  }
                                             }
                                             category {
                                                  slug
                                                  breadcrumbValue
                                                  relatedWhitePaper {
                                                       file {
                                                            url
                                                            details {
                                                                 image {
                                                                      width
                                                                      height
                                                                 }
                                                            }
                                                       }
                                                  }
                                                  parentCategory {
                                                       slug
                                                       breadcrumbValue
                                                       parentCategory {
                                                            slug
                                                            breadcrumbValue
                                                            parentCategory {
                                                                 slug
                                                                 breadcrumbValue
                                                                 parentCategory {
                                                                      slug
                                                                      breadcrumbValue
                                                                 }
                                                            }
                                                       }
                                                  }
                                             }
                                        }
                                        ctaText
                                        parentCategory {
                                             slug
                                             parentCategory {
                                                  slug
                                                  parentCategory {
                                                       slug
                                                       parentCategory {
                                                            slug
                                                       }
                                                  }
                                             }
                                        }
                                   }
                              }
                         }
                         titleBrowserContent: contentfulPage(contentful_id: { eq: "wChsWYdrCJ5doI8QdVkBk" }) {
                              id
                              rows {
                                   ... on ContentfulSectionTitleBrowse {
                                        id
                                        contentName
                                   }
                              }
                         }
                    }
               `}
               render={(data) => {
                    const topCategories = get(data, 'allContentfulHzCategory.edges');
                    const titleBrowserContent = data.titleBrowserContent.rows[3].contentName;
                    return <BrowserContentByCategory titleBrowserContent={titleBrowserContent} topCategories={topCategories}></BrowserContentByCategory>;
               }}
          />
     );
};
